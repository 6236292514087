import React from 'react';
import { graphql } from "gatsby";
import Fade from 'react-reveal/Fade';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import './index.scss';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';

import ModulesIcon from 'media/pages/modules/ModulesIcon.inline.svg';

export default function ModulesPage({data}){
    const CTATypes = ['SignUp', 'AddOns'];
    const pagePath = "/modules";

    const previewImg = {
        url: data.file.childImageSharp.fixed.src,
        height: data.file.childImageSharp.fixed.height,
        width: data.file.childImageSharp.fixed.width,
        alt: "Modules icon"
    }

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="Modules - Mashoom"
                description="Modules are Mashoom's building blocks, each designed to fulfil a specific workflow, task or use case. Each module is independent, but seamlessly integrate with other's to create a coherent solution."
                previewImg={previewImg}
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={ModulesIcon}
                imageAlt="Modules icon">
                        <h1>Modules</h1>
                        <p>Modules are Mashoom's building blocks, each designed to fulfil a specific workflow, task or use case. Each module is independent, but seamlessly integrate with other's to create a coherent solution.</p>
            </PagePicTitle>
            <Fade left>
                <FeaturePoint
                    title="Library"
                    moduleName="Library"
                    moreInfo="/modules/library/"
                >
                    <p>Store files in the cloud, as you would a file server. A complete permissions system allows granular access control for both internal employees and external clients.</p>
                    <ul>
                        <li>Simple, persistent file storage</li>
                        <li>Organize files how you are used to, just online!</li>
                        <li>Complete log of all actions and downloads</li>
                        <li>Suitable for providing and receiving tendering documents</li>
                        <li>Suitable for personal use, scales to large corporate use</li>
                    </ul>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Fade left>
                <FeaturePoint
                    title="Share"
                    moduleName="Share"
                    moreInfo="/modules/share/"
                >
                    <p>Send, distribute and receive files of any size. Quick, simple, sorted.</p>
                    <ul>
                        <li>Fast and unlimited file sharing via email</li>
                        <li>Receiving files by sending email link</li>
                        <li>Multiple recipients</li>
                        <li>Download log</li>
                        <li>Enables external users for the account</li>
                    </ul>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Fade left>
                <FeaturePoint
                    title="Contact"
                    moduleName="Contact"
                    moreInfo="/modules/contact/"
                >
                    <p>A simple yet power CRM solution</p>
                    <ul>
                        <li>An address book of all your contacts</li>
                        <li>Add activity and tasks; log what you've done and what you need to do</li>
                        <li>Stay on track with mission control, providing an efficient workflow for chasing leads</li>
                        <li>Add any number of custom fields by creating types for your contacts</li>
                        <li>Advanced filtering allows powerful analytics of your database</li>
                    </ul>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Fade left>
                <FeaturePoint
                    title="Quote"
                    moduleName="Quote"
                    moreInfo="/modules/quote/"
                >
                    <p>Fast and easy quote and proforma building</p>
                    <ul>
                        <li>Our advanced interface makes quote building a breeze, even when on the phone!</li>
                        <li>A simple workflow tracks when the quote was sent, rejected, versioned and accepted</li>
                        <li>Add activity and tasks, track customer feedback and log a reminder to chase it</li>
                        <li>Simple estimation tool to give an idea of expected cash flow</li>
                        <li>Connects to Contact and Stock module to provide powerful features</li>
                    </ul>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Fade left>
                <FeaturePoint
                    title="Forms"
                    moduleName="Forms"
                    moreInfo="/modules/forms/"
                >
                    <p>Create a form to collect data and / or generate documents.</p>
                    <ul>
                        <li>Define a set of data fields to collected</li>
                        <li>Upload a PDF template to be filled with some or all of the data</li>
                        <li>Form filled in online or offline</li>
                        <li>On completion, a static PDF file is generated and the data stored for analysis</li>
                        <li>Many advanced features for complex workflows, including signing</li>
                    </ul>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Fade left>
                <FeaturePoint
                    title="Physical Things"
                    moduleName="GenPhl"
                    moreInfo="/modules/physical_things/"
                >
                    <p>Store any information about a set of physical things</p>
                    <ul>
                        <li>Define "types"; a set of fields to collect about your items</li>
                        <li>Powerful hierarchical type structure allows simple management of complex data sets</li>
                        <li>Each "thing" needs a unique serial number; everything else is customisable</li>
                        <li>Filters enable fast and efficient searching using "AND" / "OR" statements</li>
                        <li>Fully customisable reporting including downloadable spreadsheet formats</li>
                    </ul>
                </FeaturePoint>
            </Fade>
            <Fade left>
                <FeaturePoint
                    title="Design"
                    moduleName="Design"
                    moreInfo="/modules/design/"
                >
                    <p>Centralized CAD data storage, version control and management. Everything you need to get your CAD organized and safely stored in the cloud.</p>
                    <ul>
                        <li>Centralized and version controlled CAD storage</li>
                        <li>Automated generation, updating and interpretation of your part numbering scheme</li>
                        <li>Never reads CAD data; completely independent from CAD providers</li>
                        <li>Simple but effective workflow and drawing authorization</li>
                        <li>One click download of assemblies and sub-components</li>
                    </ul>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Fade left>
                <FeaturePoint
                    title="Booking"
                    moduleName="Locations"
                    moreInfo="/modules/booking/"
                >
                    <p>Simple HR, time booking and location management</p>
                    <ul>
                        <li>Setup any number of "locations" and "activities" for people to book</li>
                        <li>Impose quotas and authorisation for bookings</li>
                        <li>Optionally setup check-in emails, allowing 1-click logging of activity</li>
                        <li>Team manager page for managers to see and manage their team's bookings</li>
                        <li>Suitable for replacing registers, hot-desking and meeting room management</li>
                    </ul>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}

//eslint-disable-next-line no-undef
export const query = graphql`
    query ModulesPreviewImg {
        file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/modules/ModulesIcon.png"}) {
            childImageSharp {
                fixed(width: 800, height: 400, fit: CONTAIN, background: "white") {
                    src
                    height
                    width
                }
            }
        }
    }
`;